// headings
@mixin headings($from: 1, $to: 6) {
	@for $i from $from through $to {
		h#{$i} {
			@content; } } }

// material icons
@mixin material-icons ($font-size: em(24)) {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  // Support for all WebKit browsers.
  -webkit-font-smoothing: antialiased;
  // Support for Safari and Chrome.
  text-rendering: optimizeLegibility;
  // Support for Firefox.
  -moz-osx-font-smoothing: grayscale;
  // Support for IE.
  font-feature-settings: 'liga'; }

