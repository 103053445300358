// mobile navigation
#menu {
	@include node-bg();
	position: fixed;
	top: -150%;
	left: 0;
	z-index: 500;
	width: 100%;
	min-height: 100vh;
	transition: $base-transition;
	color: white;
	nav {
		margin: 0;
		min-height: 100vh;
		padding: $base-spacing {
			top: 140px; }
		position: relative;
		text-align: center;
		@include flex-container($direction: column);
		a.logo {
			margin: 0 auto;
			display: block;
			position: relative;
			> * {
				height: 180px; } }
		ul {
			font-family: $heading-font-family;
			font-size: em(20);
			flex-grow: 1;
			padding: $base-spacing;
			max-height: 600px;
			@include flex-container($direction: column, $justify-content: space-around);
			li {
				margin-bottom: $base-spacing;
				display: block;
				font-weight: $bold-font-weight;
				a {
					color: white;
					display: block;
					&:hover, &.active {
						color: $brand-color-3; } } } }

		p {
			font-size: em(14);
			margin: 0; } } }

// active navigation
body.nav-active {
	#wrapper {
		overflow: hidden;
		max-height: 100%; }
	#menu {
		top: 0;
		nav {
			min-width: 100%; } } }

// default navigation
#wrapper header {
	nav {
		position: relative;
		flex-grow: 1;
		text-align: right;
		ul {
			display: none; }
		div.dropdown {
			position: relative;
			display: inline-block;
			&:hover div.dropdown-content {
				display: block; } }
		div.dropdown-content {
			background: $brand-color-1;
			display: none;
			position: absolute;
			a {
				display: block; } }
		#menuTrigger {
			$bar-height: 4px;
			border: 2px solid $brand-color-1;
			background-color: white;
			padding: 8px;
			z-index: 1000;
			position: relative;
			display: inline-block;
			a {
				width: 32px;
				height: 28px;
				transform: rotate(0deg);
				transition: .125s $base-timing;
				cursor: pointer;
				display: block;
				position: relative;
				span {
					display: block;
					position: absolute;
					left: 0;
					height: $bar-height;
					width: 100%;
					background: $brand-color-1;
					opacity: 1;
					transform: rotate(0deg);
					transition: .125s $base-timing;
					&:nth-child(1) {
						top: 0px; }
					&:nth-child(2), &:nth-child(3) {
						top: $bar-height*3; } // height *2
					&:nth-child(4) {
						top: $bar-height*6; } } } // height *4
			&.open {
				background-color: transparent;
				a span {
					&:nth-child(1) {
						top: $bar-height*2; // height *2
						width: 0%;
						left: 50%; }
					&:nth-child(2) {
						transform: rotate(45deg); }
					&:nth-child(3) {
						transform: rotate(-45deg); }
					&:nth-child(4) {
						top: $bar-height*2; // height *2
						width: 0%;
						left: 50%; } } } } } }
#wrapper #subNav {
	position: absolute;
	z-index: 400;
	width: 100%;
	background-color: white;
	&.scrolled {
		position: fixed;
		top: 0;
		margin-top: 0; }
	+ #pageContent {
		padding-top: 120px; }
	> * {
		border-bottom: 2px solid tint(black,80%);
		overflow-y: scroll;
		max-height: 85vh;
		> .trigger {
			padding: $tiny-spacing em(24,18);
			margin: 0;
			cursor: pointer;
			background-color: white;
			&.collapsible:before {
				content: "\e5c5";
				float: right;
				margin: {
					top: -3px;
					left: $base-spacing; }
				@include material-icons(); }
			&.open {
				// color: tint($base-color,20%)
				&:before {
					content: "\e5c7"; } }
			&.no-icon {
				&:before {
					display: none; } }
			+ div {
				display: none;
				background-color: tint($brand-color-3,90%); } }

		ul {
			padding: $tiny-spacing $base-spacing;
			li span.collapsible {
				cursor: pointer;
				color: $action-color;
				&:after {
					@include material-icons();
					content: "\e5c5";
					position: relative;
					top: 0.25em; }
				&.open:after {
					content: "\e5c7"; } } } }




	#sectionNav {
		#sectionNavTrigger {
			color: $brand-color-1; } } }




#wrapper > footer {
	nav ul li {
		display: inline-block;
		padding: $base-spacing;
		font-size: em(20);
		font-weight: 700;
		a {
			color: $brand-color-2;
			&:hover {
				color: $brand-color-1; } } } }


#pagination {
	@include flex-container;
	border-top: $base-border;
	padding-top: $base-spacing;
	> * {
		font-size: em(40); }

	.disabled {
		color: $base-background-color;
		cursor: default; } }

@media screen and (min-width: $medium-screen) {
	#wrapper {
		#subNav {
			background-color: transparent;
			@include flex-container($align-items: flex-start, $justify-content: flex-end);
			> * {
				flex-basis: 50%;
				&:not(:first-child) .trigger {
					border-left: 1px solid tint(black,90%); } }
			+ #pageContent {
				padding-top: 80px; } } } }


@media screen and (min-width: $m-large-screen) {
	#menu {
		display: none; }

	#wrapper {
		#subNav {
			right: 0;
			width: calc(100% - 191px); // positioned to right of logo
			z-index: 900;
			&.scrolled {
				width: 100%; }
			> * {
				& .trigger {
					padding: {
						top: 16px;
						bottom: 16px; }
					font-size: em(15);
					+ div {
						font-size: em(14); } } }

			+ #pageContent {
				padding-top: 80px; } }


		header {
			nav {
				background: $brand-color-1;
				position: relative;
				width: 100%;
				padding-left: 180px;
				#menuTrigger {
					display: none; }
				ul {
					position: relative;
					text-align: right;
					display: block;
					font-size: em(16);
					li {
						display: inline-block;
						font-family: $heading-font-family;
						font-weight: 700;
						margin-right: -4px;
						a {
							padding: 31px $base-spacing;
							color: white;
							display: inline-block;
							&:hover, &.active {
								background: $brand-color-3; } } }
					#versions {
						text-align: left; } } } } } }


@media screen and (min-width: $large-screen) {
	#wrapper {
		#subNav {
			display: block;
			position: static;
			&.scrolled {
				width: auto;
				position: static;
				> * {
					top: 0;
					max-height: 100%;
					position: fixed;
					top: 0;
					.trigger + div {} } }

			> * {
				top: 70px;
				max-height: calc(100vh - 155px); // subtract height of header
				position: absolute;
				border-bottom: none;
				&#sectionNav {
					width: 20%;
					left: 0;
					ul:first-child, .trigger {
						padding-left: 48px; } }
				&#pageNav {
					width: 15%;
					right: 0; }
				&:not(:first-child) .trigger {
					border-left: none; } }

			+ #pageContent {
				margin: 0 15% 0 20%;
				width: 65%;
				padding-right: $large-spacing;
				padding-top: 0; } }




		header {
			nav {
				ul {
					font-size: em(18);
					li a {
						padding: 29px $base-spacing; } } } } } }


@media screen and (min-width: $x-large-screen) {
	// #wrapper
	// 	#subNav
	//// position: relative
	//// flex-basis: 25%

	//// > *
	//// 	.trigger
	//// 		padding-left: em(24,18)
	//// 	ul
	//// 		padding-left: $base-spacing
	//// + #pageContent
	//	// flex-basis: 75%
	//	// margin-left: 0

	//// &.scrolled
	//// 	position: fixed
	//// 	width: 400px
	//// 	left: -1200px
	//// 	margin: 0 auto
	//// 	+ #pageContent
 }	//// 		margin-left: 25%

