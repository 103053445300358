* {
  box-sizing: border-box; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, code, img, strike, strong, sub, sup,
fieldset, figcaption, figure, form, label, q,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, figure, figcaption, footer, header,
menu, nav, section, summary, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none; }

body {
  -webkit-text-size-adjust: none; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

a {
  text-decoration: none;
  color: inherit; }
  a img {
    border: 0; }

main, figcaption, figure {
  display: block; }

table, th, tbody, tr, td {
  border: 0; }

[type=text], [type=email], [type=number], [type=password], textarea {
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  letter-spacing: inherit; }
  [type=text]:focus, [type=email]:focus, [type=number]:focus, [type=password]:focus, textarea:focus {
    border-color: transparent;
    outline: none; }

button, input[type=submit] {
  font: inherit; }
  button:focus, input[type=submit]:focus {
    outline: none; }

button, [type='button'], [type='reset'], [type='submit'], .button {
  appearance: none;
  background-color: #4279f4;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.25em;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, [type='button']:hover, [type='reset']:hover, [type='submit']:hover, .button:hover {
    background-color: #b7b7b7;
    color: white; }
  button:focus, [type='button']:focus, [type='reset']:focus, [type='submit']:focus, .button:focus {
    background-color: #757575; }
  button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled, .button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover, .button:disabled:hover {
      background-color: #4279f4; }

form {
  padding: 1.5em 0;
  margin-bottom: 1.5em; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }
  fieldset .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch; }
    fieldset .fields > * {
      flex: 0 1 auto; }
    fieldset .fields .input {
      flex-basis: 100%; }
      fieldset .fields .input label {
        display: none; }

legend {
  font-weight: 900;
  margin-bottom: 0.375em;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.25em;
  color: #4279f4; }

label {
  display: block;
  font-weight: 900;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1em; }

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea {
  appearance: none;
  background-color: #e6e6e6;
  border: 2px solid #4279f4;
  border-color: transparent;
  box-sizing: border-box;
  margin-bottom: 1.5em;
  padding: 0.75em;
  transition: all 150ms ease;
  width: 100%; }
  [type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus {
    border-color: #6ca1ff;
    background-color: #f0f6ff;
    outline: none; }
  [type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled {
    background-color: #dbdbdb;
    cursor: not-allowed; }
    [type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
      border: 2px solid #4279f4; }
  [type='color']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='email']::placeholder, [type='month']::placeholder, [type='number']::placeholder, [type='password']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='text']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='week']::placeholder, input:not([type])::placeholder, textarea::placeholder {
    color: #7a7a7a; }

textarea {
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  width: 100%; }

input[type="submit"] {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-weight: 900;
  font-size: 1.25em; }

@media screen and (min-width: 480px) {
  fieldset .fields .input {
    flex-basis: 50%; }
    fieldset .fields .input:nth-child(odd) {
      padding-right: 0.75em; }
    fieldset .fields .input:nth-child(even) {
      padding-left: 0.75em; }
    fieldset .fields .input.full {
      padding: 0;
      flex-basis: 100%; } }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html,
body {
  height: 100%; }

.contain {
  margin: 0 auto;
  max-width: 1200px; }

body > #wrapper {
  position: relative; }
  body > #wrapper header {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 800;
    background: white;
    border-bottom: 2px solid #4279f4;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5em 0 4px; }
    body > #wrapper header::after {
      clear: both;
      content: "";
      display: block; }
    body > #wrapper header > * {
      flex: 0 1 auto; }
    body > #wrapper header .logo {
      display: block;
      position: relative;
      height: 130px;
      padding: 20px;
      background: white;
      z-index: 700; }
      body > #wrapper header .logo > * {
        width: auto;
        height: 100%; }
  body > #wrapper #hero {
    position: relative;
    background: url("/images/bg-w.svg") center center/cover no-repeat #213d7a;
    color: white;
    text-align: center;
    position: relative;
    padding: 3em 1.5em; }
    body > #wrapper #hero:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background: url("/images/bg-k.svg") center center/cover no-repeat; }
    body > #wrapper #hero .contain {
      position: relative; }
      body > #wrapper #hero .contain h1 {
        font-size: 3.375em; }
        body > #wrapper #hero .contain h1:after {
          content: "";
          display: block;
          margin: 0.5em auto 0;
          height: 4px;
          background-color: white;
          width: 20%; }
      body > #wrapper #hero .contain h5 {
        font-weight: 400;
        color: white; }
      body > #wrapper #hero .contain .button {
        margin-top: 1.5em; }
  body > #wrapper #contentWrap {
    position: relative; }
  body > #wrapper #pageContent {
    padding: 1.5em;
    margin-bottom: 1.5em; }
    body > #wrapper #pageContent *:first-child {
      margin-top: 0; }
    body > #wrapper #pageContent .columns {
      margin: 1.5em 0; }
      body > #wrapper #pageContent .columns > *:not(:last-child) {
        margin-bottom: 1.5em; }
  body > #wrapper > footer {
    border-top: 2px solid #e6e6e6;
    text-align: center;
    margin-top: 1.5em;
    position: relative;
    z-index: 900;
    background: white; }
    body > #wrapper > footer > div {
      position: relative;
      background: url("/images/bg-w.svg") center center/cover no-repeat #213d7a;
      padding: 1.5em;
      min-height: 200px; }
      body > #wrapper > footer > div:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: url("/images/bg-k.svg") center center/cover no-repeat; }
      body > #wrapper > footer > div p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(255, 255, 255, 0.7);
        width: 100%;
        padding: 0 1.5em; }
        body > #wrapper > footer > div p a {
          color: rgba(255, 255, 255, 0.7);
          padding: 0 0.5em;
          border-right: 1px solid white; }
          body > #wrapper > footer > div p a:hover {
            color: white; }
          body > #wrapper > footer > div p a:last-of-type {
            border-right: none;
            padding-right: none; }
  @media screen and (min-width: 480px) {
    body > #wrapper #pageContent .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      margin: 3em 0; }
      body > #wrapper #pageContent .columns > * {
        flex: 0 1 auto; }
      body > #wrapper #pageContent .columns > * {
        flex-basis: 50%;
        margin: 0 !important; }
        body > #wrapper #pageContent .columns > *:nth-child(odd) {
          padding-right: 1.5em; }
        body > #wrapper #pageContent .columns > *:nth-child(even) {
          padding-left: 1.5em; } }
  @media screen and (min-width: 769px) {
    body > #wrapper #pageContent {
      max-width: 1200px;
      margin: 0 auto;
      padding: 1.5em 3em; } }
  @media screen and (min-width: 960px) {
    body > #wrapper #hero {
      padding-top: 6em;
      padding-bottom: 6em; }
    body > #wrapper header {
      display: block;
      border-bottom: none;
      background: transparent;
      padding: 0; }
      body > #wrapper header .logo {
        position: absolute;
        left: 0;
        top: 0;
        height: 140px;
        display: inline-block;
        margin-left: 3em;
        border: 2px solid #4279f4;
          border-top: none; } }
  @media screen and (min-width: 1200px) {
    body > #wrapper #contentWrap {
      padding-top: 70px; } }

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 900;
  margin: 0; }

dd {
  margin: 0; }

#pageContent ul, #pageContent ol {
  overflow: hidden; }
  #pageContent ul li, #pageContent ol li {
    margin-bottom: 0.5em; }
    #pageContent ul li ul, #pageContent ul li ol, #pageContent ol li ul, #pageContent ol li ol {
      margin-top: 0.25em; }
    #pageContent ul li li, #pageContent ol li li {
      margin-bottom: 0.25em; }
    #pageContent ul li > p:first-child, #pageContent ol li > p:first-child {
      display: inline-block; }

#pageContent ul {
  list-style-type: none;
  padding-left: 1.5em; }
  #pageContent ul li {
    position: relative; }
    #pageContent ul li:before {
      width: 3em;
      margin-left: -3em;
      top: -0.25em;
      position: relative;
      text-align: center;
      content: "\f111";
      font-family: "Font Awesome 5 Free";
      font-size: 0.375em;
      font-weight: 700;
      color: #4279f4;
      padding-right: 0.75em;
      display: inline-block; }
    #pageContent ul li ul li:before {
      font-weight: 400; }
    #pageContent ul li ul li ul li:before {
      content: "\f0c8";
      font-weight: 700; }
    #pageContent ul li ol li:before {
      font-size: 1em;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
      top: 0; }

#pageContent ol {
  list-style-type: none;
  padding-left: 3em;
  counter-reset: li; }
  #pageContent ol li {
    counter-increment: li; }
    #pageContent ol li:before {
      content: counter(li);
      color: #4279f4;
      display: inline-block;
      width: 3em;
      margin-left: -3em;
      position: relative;
      text-align: right;
      padding-right: 0.5em; }
  #pageContent ol ol {
    padding-left: 1.5em;
    counter-reset: li; }
    #pageContent ol ol li {
      counter-increment: li; }
      #pageContent ol ol li:before {
        content: counter(li, lower-alpha); }
      #pageContent ol ol li ol li:before {
        content: counter(li, lower-roman); }
  #pageContent ol ul li {
    counter-increment: none; }
    #pageContent ol ul li:before {
      content: "\f111";
      font-weight: 400;
      padding-right: 0.75em; }

figure {
  margin: 0; }

img,
picture,
svg {
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  height: auto; }

iframe {
  display: block;
  width: 100%; }

figcaption {
  padding: 1.5em 0.75em;
  border-bottom: 2px solid #e6e6e6;
  color: #757575;
  width: 100%;
  font-size: .9em;
  font-style: italic;
  text-align: center; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #325bb7;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 2px solid #4279f4;
  padding: 0.75em 0; }

tr, td, th {
  vertical-align: middle; }

.material-icons.md-18 {
  font-size: 1.125em; }

.material-icons.md-24 {
  font-size: 1.5em; }

.material-icons.md-36 {
  font-size: 2.25em; }

.material-icons.md-48 {
  font-size: 3em; }

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

body {
  color: #212121;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 400; }

h1 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: calc(1em / 1.5) 0;
  font-weight: 900; }
  h1:first-child {
    margin-top: 0; }

h2 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: calc(1em / 1.5) 0;
  font-weight: 900; }
  h2:first-child {
    margin-top: 0; }

h3 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: calc(1em / 1.5) 0;
  font-weight: 900; }
  h3:first-child {
    margin-top: 0; }

h4 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: calc(1em / 1.5) 0;
  font-weight: 900; }
  h4:first-child {
    margin-top: 0; }

h5 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: calc(1em / 1.5) 0;
  font-weight: 900; }
  h5:first-child {
    margin-top: 0; }

h6 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: calc(1em / 1.5) 0;
  font-weight: 900; }
  h6:first-child {
    margin-top: 0; }

h1 {
  font-size: 2.625em; }

h2 {
  font-size: 2em; }

h3 {
  font-size: 1.5em; }

h4 {
  font-size: 1.25em;
  color: #4279f4; }

h5 {
  font-size: 1em;
  color: #4279f4; }

h6 {
  font-size: 0.875em;
  text-transform: uppercase; }

p {
  margin: 0 0 0.75em; }
  p:last-child {
    margin-bottom: 0; }
  p.intro {
    color: #4279f4;
    font-size: 1.25em;
    margin: 3em 0; }
  p a {
    text-decoration: underline; }

a {
  color: #4279f4;
  text-decoration: none;
  transition: color 150ms ease; }
  a.current-page-in-section {
    color: #212121;
    font-weight: bold; }
  a:active, a:focus, a:hover {
    color: #212121; }

hr {
  border-bottom: 2px solid #4279f4;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

strong {
  font-weight: 900; }

em {
  font-style: italic; }

sub, sup {
  font-size: 75%;
  padding-left: 0.2em;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

pre {
  font-family: "Roboto Mono", "Courier New", "Courier", "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  white-space: pre-wrap;
  padding: 0.75em;
  border: 1px solid rgba(66, 121, 244, 0.5);
  background-color: #f0f6ff;
  margin: 1.5em 0; }
  pre strong {
    font-weight: 700; }
  pre code {
    overflow-wrap: break-word; }

blockquote {
  font-size: 2em;
  color: #4279f4;
  font-weight: 900;
  text-align: right;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  margin: 3em 1.5em; }
  blockquote > * {
    text-align: center; }
  blockquote cite {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    font-size: 0.5625em;
    border-top: 1px solid #757575;
    text-align: right;
    color: #757575;
    padding: 0.5em 1.5em; }

.center {
  text-align: center; }

@media screen and (min-width: 769px) {
  h1 {
    font-size: 2.625em; }
  h2 {
    font-size: 2em; }
  h3 {
    font-size: 1.5em; }
  h4 {
    font-size: 1.25em; }
  h5 {
    font-size: 1em; }
  h6 {
    font-size: 0.875em; } }

.block {
  position: relative;
  margin: 1.5em auto; }
  .block * {
    position: relative; }
  .block.wrap, .block > .wrap {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .block.wrap::after, .block > .wrap::after {
      clear: both;
      content: "";
      display: block; }
  .block.full {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .block.full::after {
      clear: both;
      content: "";
      display: block; }
  .block.img.bg {
    background-size: cover;
    background-position: center center;
    min-height: 70vh; }
  .block.layout {
    display: flex;
    flex-direction: column; }
    .block.layout .wrap > * {
      padding: 1.5em; }
      @media screen and (min-width: 769px) {
        .block.layout .wrap > * {
          width: 60%; }
          .block.layout .wrap > *.right {
            margin-left: 40%; }
          .block.layout .wrap > *.center {
            margin: 0 auto; }
          .block.layout .wrap > *.full {
            width: 100%; } }
    .block.layout.vert-top {
      justify-content: flex-start; }
    .block.layout.vert-center {
      justify-content: center; }
    .block.layout.vert-bottom {
      justify-content: flex-end; }
  .block.overlay * {
    z-index: 5; }
  .block.overlay-black {
    color: white; }
    .block.overlay-black:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2); }
    .block.overlay-black.percent-0:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0); }
    .block.overlay-black.percent-20:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2); }
    .block.overlay-black.percent-50:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.5); }
    .block.overlay-black.percent-80:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.8); }
    .block.overlay-black.percent-100:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: black; }
  .block.overlay-white {
    color: #212121; }
    .block.overlay-white:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.2); }
    .block.overlay-white.percent-0:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0); }
    .block.overlay-white.percent-20:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.2); }
    .block.overlay-white.percent-50:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.5); }
    .block.overlay-white.percent-80:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba(255, 255, 255, 0.8); }
    .block.overlay-white.percent-100:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: white; }

.image {
  display: block; }
  .image img {
    display: block;
    width: 100%;
    height: auto; }
  .image.left, .image.right {
    max-width: 45%; }
    .image.left::after, .image.right::after {
      clear: both;
      content: "";
      display: block; }
  .image.left {
    float: left;
    margin: 0 1.5em 1.5em 0; }
  .image.right {
    float: right;
    margin: 0 0 1.5em 1.5em; }

#menu {
  position: relative;
  background: url("/images/bg-w.svg") center center/cover no-repeat #213d7a;
  position: fixed;
  top: -150%;
  left: 0;
  z-index: 500;
  width: 100%;
  min-height: 100vh;
  transition: all 150ms ease;
  color: white; }
  #menu:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    background: url("/images/bg-k.svg") center center/cover no-repeat; }
  #menu nav {
    margin: 0;
    min-height: 100vh;
    padding: 1.5em;
      padding-top: 140px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch; }
    #menu nav > * {
      flex: 0 1 auto; }
    #menu nav a.logo {
      margin: 0 auto;
      display: block;
      position: relative; }
      #menu nav a.logo > * {
        height: 180px; }
    #menu nav ul {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
      font-size: 1.25em;
      flex-grow: 1;
      padding: 1.5em;
      max-height: 600px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch; }
      #menu nav ul > * {
        flex: 0 1 auto; }
      #menu nav ul li {
        margin-bottom: 1.5em;
        display: block;
        font-weight: 900; }
        #menu nav ul li a {
          color: white;
          display: block; }
          #menu nav ul li a:hover, #menu nav ul li a.active {
            color: #6ca1ff; }
    #menu nav p {
      font-size: 0.875em;
      margin: 0; }

body.nav-active #wrapper {
  overflow: hidden;
  max-height: 100%; }

body.nav-active #menu {
  top: 0; }
  body.nav-active #menu nav {
    min-width: 100%; }

#wrapper header nav {
  position: relative;
  flex-grow: 1;
  text-align: right; }
  #wrapper header nav ul {
    display: none; }
  #wrapper header nav div.dropdown {
    position: relative;
    display: inline-block; }
    #wrapper header nav div.dropdown:hover div.dropdown-content {
      display: block; }
  #wrapper header nav div.dropdown-content {
    background: #4279f4;
    display: none;
    position: absolute; }
    #wrapper header nav div.dropdown-content a {
      display: block; }
  #wrapper header nav #menuTrigger {
    border: 2px solid #4279f4;
    background-color: white;
    padding: 8px;
    z-index: 1000;
    position: relative;
    display: inline-block; }
    #wrapper header nav #menuTrigger a {
      width: 32px;
      height: 28px;
      transform: rotate(0deg);
      transition: 0.125s ease;
      cursor: pointer;
      display: block;
      position: relative; }
      #wrapper header nav #menuTrigger a span {
        display: block;
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        background: #4279f4;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.125s ease; }
        #wrapper header nav #menuTrigger a span:nth-child(1) {
          top: 0px; }
        #wrapper header nav #menuTrigger a span:nth-child(2), #wrapper header nav #menuTrigger a span:nth-child(3) {
          top: 12px; }
        #wrapper header nav #menuTrigger a span:nth-child(4) {
          top: 24px; }
    #wrapper header nav #menuTrigger.open {
      background-color: transparent; }
      #wrapper header nav #menuTrigger.open a span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%; }
      #wrapper header nav #menuTrigger.open a span:nth-child(2) {
        transform: rotate(45deg); }
      #wrapper header nav #menuTrigger.open a span:nth-child(3) {
        transform: rotate(-45deg); }
      #wrapper header nav #menuTrigger.open a span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%; }

#wrapper #subNav {
  position: absolute;
  z-index: 400;
  width: 100%;
  background-color: white; }
  #wrapper #subNav.scrolled {
    position: fixed;
    top: 0;
    margin-top: 0; }
  #wrapper #subNav + #pageContent {
    padding-top: 120px; }
  #wrapper #subNav > * {
    border-bottom: 2px solid #cccccc;
    overflow-y: scroll;
    max-height: 85vh; }
    #wrapper #subNav > * > .trigger {
      padding: 0.5em 1.33333em;
      margin: 0;
      cursor: pointer;
      background-color: white; }
      #wrapper #subNav > * > .trigger.collapsible:before {
        content: "\e5c5";
        float: right;
        margin-top: -3px;
        margin-left: 1.5em;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga'; }
      #wrapper #subNav > * > .trigger.open:before {
        content: "\e5c7"; }
      #wrapper #subNav > * > .trigger.no-icon:before {
        display: none; }
      #wrapper #subNav > * > .trigger + div {
        display: none;
        background-color: #f0f6ff; }
    #wrapper #subNav > * ul {
      padding: 0.5em 1.5em; }
      #wrapper #subNav > * ul li span.collapsible {
        cursor: pointer;
        color: #4279f4; }
        #wrapper #subNav > * ul li span.collapsible:after {
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          display: inline-block;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
          content: "\e5c5";
          position: relative;
          top: 0.25em; }
        #wrapper #subNav > * ul li span.collapsible.open:after {
          content: "\e5c7"; }
  #wrapper #subNav #sectionNav #sectionNavTrigger {
    color: #4279f4; }

#wrapper > footer nav ul li {
  display: inline-block;
  padding: 1.5em;
  font-size: 1.25em;
  font-weight: 700; }
  #wrapper > footer nav ul li a {
    color: #0028aa; }
    #wrapper > footer nav ul li a:hover {
      color: #4279f4; }

#pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  border-top: 2px solid #4279f4;
  padding-top: 1.5em; }
  #pagination > * {
    flex: 0 1 auto; }
  #pagination > * {
    font-size: 2.5em; }
  #pagination .disabled {
    color: #e6e6e6;
    cursor: default; }

@media screen and (min-width: 769px) {
  #wrapper #subNav {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start; }
    #wrapper #subNav > * {
      flex: 0 1 auto; }
    #wrapper #subNav > * {
      flex-basis: 50%; }
      #wrapper #subNav > *:not(:first-child) .trigger {
        border-left: 1px solid #e6e6e6; }
    #wrapper #subNav + #pageContent {
      padding-top: 80px; } }

@media screen and (min-width: 960px) {
  #menu {
    display: none; }
  #wrapper #subNav {
    right: 0;
    width: calc(100% - 191px);
    z-index: 900; }
    #wrapper #subNav.scrolled {
      width: 100%; }
    #wrapper #subNav > * .trigger {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 0.9375em; }
      #wrapper #subNav > * .trigger + div {
        font-size: 0.875em; }
    #wrapper #subNav + #pageContent {
      padding-top: 80px; }
  #wrapper header nav {
    background: #4279f4;
    position: relative;
    width: 100%;
    padding-left: 180px; }
    #wrapper header nav #menuTrigger {
      display: none; }
    #wrapper header nav ul {
      position: relative;
      text-align: right;
      display: block;
      font-size: 1em; }
      #wrapper header nav ul li {
        display: inline-block;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
        font-weight: 700;
        margin-right: -4px; }
        #wrapper header nav ul li a {
          padding: 31px 1.5em;
          color: white;
          display: inline-block; }
          #wrapper header nav ul li a:hover, #wrapper header nav ul li a.active {
            background: #6ca1ff; }
      #wrapper header nav ul #versions {
        text-align: left; } }

@media screen and (min-width: 1200px) {
  #wrapper #subNav {
    display: block;
    position: static; }
    #wrapper #subNav.scrolled {
      width: auto;
      position: static; }
      #wrapper #subNav.scrolled > * {
        top: 0;
        max-height: 100%;
        position: fixed;
        top: 0; }
    #wrapper #subNav > * {
      top: 70px;
      max-height: calc(100vh - 155px);
      position: absolute;
      border-bottom: none; }
      #wrapper #subNav > *#sectionNav {
        width: 20%;
        left: 0; }
        #wrapper #subNav > *#sectionNav ul:first-child, #wrapper #subNav > *#sectionNav .trigger {
          padding-left: 48px; }
      #wrapper #subNav > *#pageNav {
        width: 15%;
        right: 0; }
      #wrapper #subNav > *:not(:first-child) .trigger {
        border-left: none; }
    #wrapper #subNav + #pageContent {
      margin: 0 15% 0 20%;
      width: 65%;
      padding-right: 3em;
      padding-top: 0; }
  #wrapper header nav ul {
    font-size: 1.125em; }
    #wrapper header nav ul li a {
      padding: 29px 1.5em; } }

#overview {
  border-bottom: 2px solid #b6d0ff;
  padding: 3em 1.5em;
  text-align: center;
  font-size: 1.125em;
  margin-bottom: 1.5em; }

#pageContent .lead {
  margin: 3em 0; }
  #pageContent .lead > .image {
    padding: 0 1.5em;
    max-width: 60%;
    margin-bottom: 1.5em; }
  #pageContent .lead > .text p {
    font-size: 1.125em; }

@media screen and (min-width: 480px) {
  #overview {
    font-size: 1.25em; }
  #pageContent .lead > .image {
    max-width: 35%;
    margin-bottom: 3em; }
  #pageContent .lead > .text p {
    font-size: 1.25em; } }

@media screen and (min-width: 769px) {
  #pageContent .lead {
    margin-top: 6em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch; }
    #pageContent .lead > * {
      flex: 0 1 auto; }
    #pageContent .lead > .image {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
      #pageContent .lead > .image > img {
        max-width: 80%;
        margin: 0 auto; }
    #pageContent .lead > .text {
      flex-basis: 70%; }
    #pageContent .lead:nth-child(2n+0) > .image {
      order: 2; }
    #pageContent .lead:nth-child(2n+0) > .text {
      order: 1; } }
