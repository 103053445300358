figure {
	margin: 0; }

img,
picture,
svg {
	margin: 0;
	max-width: 100%;
	max-height: 100%;
	height: auto; }

iframe {
	display: block;
	width: 100%; }

figcaption {
	padding: $base-spacing $small-spacing;
	border-bottom: 2px solid tint(black,90%);
	color: $base-color-medium;
	width: 100%;
	font-size: .9em;
	font-style: italic;
	text-align: center; }

