// default block content styles
.image {
	// min-height: 300px
	// min-width: 150px
	display: block;
	img {
		display: block;
		width: 100%;
		height: auto; }
	// image container positioning hooks
	&.left, &.right {
		@include clearfix;
		max-width: 45%; }
	&.left {
		float: left;
		margin: 0 $base-spacing $base-spacing 0; }
	&.right {
		float: right;
		margin: 0 0 $base-spacing $base-spacing; }

	&.center {}
	&.full {} }

