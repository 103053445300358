form {
	padding: $base-spacing 0;
	margin-bottom: $base-spacing; }

fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	.fields {
		@include flex-container($wrap: wrap);
		.input {
			flex-basis: 100%;
			label {
				display: none; } } } }

legend {
	font-weight: $bold-font-weight;
	margin-bottom: $small-spacing / 2;
	padding: 0;
	font-family: $heading-font-family;
	font-size: $h4-font-size;
	color: $brand-color-1; }


label {
	display: block;
	font-weight: $bold-font-weight;
	margin-bottom: $small-spacing / 2; }

input,
select,
textarea {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size; }

#{$all-text-inputs} {
	appearance: none;
	background-color: $base-background-color;
	border: $base-border;
	border-color: transparent;
	// border-radius: $base-border-radius
	// box-shadow: $form-box-shadow
	box-sizing: border-box;
	margin-bottom: $base-spacing;
	padding: $small-spacing;
	transition: all $base-duration $base-timing;
	width: 100%;

	// &:hover
	// 	border-color: shade($base-border-color, 20%)

	&:focus {
		border-color: $brand-color-3;
		background-color: tint($brand-color-3, 90%);
		// box-shadow: $form-box-shadow-focus
		outline: none; }

	&:disabled {
		background-color: shade($base-background-color, 5%);
		cursor: not-allowed;

		&:hover {
			border: $base-border; } }

	&::placeholder {
		color: tint($base-font-color, 40%); } }

textarea {
	resize: vertical; }

[type="checkbox"],
[type="radio"] {
	display: inline;
	margin-right: $small-spacing / 2; }

[type="file"] {
	margin-bottom: $small-spacing;
	width: 100%; }

select {
	margin-bottom: $small-spacing;
	width: 100%; }

input[type="submit"] {
	font-family: $heading-font-family;
	font-weight: $bold-font-weight;
	font-size: em(20); }

@media screen and (min-width: $small-screen) {
	fieldset .fields {
		.input {
			flex-basis: 50%;
			&:nth-child(odd) {
				padding-right: $small-spacing; }
			&:nth-child(even) {
				padding-left: $small-spacing; }
			&.full {
				padding: 0;
				flex-basis: 100%; } } } }


