* {
	box-sizing: border-box; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, code, img, strike, strong, sub, sup,
fieldset, figcaption, figure, form, label, q,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, figure, figcaption, footer, header,
menu, nav, section, summary, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	outline: none; }

body {
	-webkit-text-size-adjust: none; }

ol, ul {
	list-style: none; }

blockquote, q {
	quotes: none; }

a {
	text-decoration: none;
	color: inherit;

	img {
		border: 0; } }

main, figcaption, figure {
	display: block; }

table, th, tbody, tr, td {
	border: 0; }

[type=text], [type=email], [type=number], [type=password], textarea {
	padding: 0;
	border: none;
	background: none;
	font: inherit;
	letter-spacing: inherit;

	&:focus {
		border-color: transparent;
		outline: none; } }


button, input[type=submit] {
	font: inherit;
	&:focus {
		outline: none; } }
