#{$all-buttons},.button {
	appearance: none;
	background-color: $action-color;
	border: 0;
	// border-radius: $base-border-radius
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: $heading-font-family;
	font-size: em(20);
	-webkit-font-smoothing: antialiased;
	font-weight: $bold-font-weight;
	line-height: 1;
	padding: $small-spacing $base-spacing;
	text-align: center;
	text-decoration: none;
	transition: background-color $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;

	&:hover {
		background-color: $base-color-light;
		color: white; }
	&:focus {
		background-color: $base-color-medium; }

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			background-color: $action-color; } } }


